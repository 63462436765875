<script setup lang="ts">
const i18nHead = useLocaleHead({ addSeoAttributes: true })

useHead({
  title: () => `${$t('cIzh0GseyQ-eBJeUIB_Jt')} - ${$t('ZtzqRdD1HHSKd7-9mLEPS')}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { property: 'og:image', content: '/favicon.svg' },
    { name: 'description', content: () => `${$t('W9xT0xOEz2Fqskw1Rn7Xv')}，${$t('va2l6qnwD615aEMKBU2JQ')}` },
  ],
})
</script>

<template>
  <article class="feq-root ">
    <div class="feq-content page-content-width">
      <h1>{{ $t('cIzh0GseyQ-eBJeUIB_Jt') }}</h1>
      <p>{{ $t('W9xT0xOEz2Fqskw1Rn7Xv') }}</p>
      <p>{{ $t('WMgUsengelUGsFuCQA1Yf') }}</p>
      <p>{{ $t('P5DFEXshKjLr93fpHzUGG') }}</p>
      <p>{{ $t('3dFGCBhowfIlKf4_J7Z3h') }}</p>
      <p>{{ $t('M14qerKVeJ9oAxZVGXu_T') }}</p>
      <p>{{ $t('ordhp-g1G6rTwmQ0DYWUb') }}</p>
      <p>{{ $t('ZIJOcAQ6SOeJ07ulzuMB8') }}</p>
      <p>{{ $t('m0srRzAoHEcVnzs3CwXo') }}</p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import './style/help-content-page';
</style>
